import type { FC, ReactNode } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from '../../hooks/useAuth'

interface AuthGuardProps {
    children: ReactNode
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
    const { children } = props
    const auth = useAuth()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!auth.isAuthenticated) {
            navigate('/authentication/login')
        }
    }, [auth.isAuthenticated, location.pathname, navigate])

    return <>{auth.isAuthenticated ? children : null}</>
}

AuthGuard.propTypes = {
    children: PropTypes.node,
}

export default AuthGuard
